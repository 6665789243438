import LazyLoad from 'vanilla-lazyload';
import { toggleInfoPanel } from './components/toggleInfoPanel.js';
// import { toggleIndexPanel } from './components/toggleIndexPanel.js';
// import { toggleIndexFormat } from './components/toggleIndexFormat.js';
// import { toggleIndexListThumb } from './components/toggleIndexListThumb.js';
import { initProjectSlider, initProjectSliderCursor } from './components/initProjectSlider.js';
// import { initPreviewSlider } from './components/initPreviewSlider.js';

if (document.querySelector('.lazy')) {
	var ll = new LazyLoad();
}

if (document.querySelector('[data-panel-info]')) {
	toggleInfoPanel();
}

// if (document.querySelector('[data-panel-index]')) {
// 	toggleIndexPanel();
// }

// if (document.querySelector('[data-index]')) {
// 	toggleIndexFormat();
// }

// if (document.querySelector('[data-index-list]')) {
// 	toggleIndexListThumb();
// }

// if (document.querySelector('.preview-wrapper .slider')) {
// 	initPreviewSlider();
// }

if (document.querySelector('.project-wrapper .slider')) {
	initProjectSlider();
	initProjectSliderCursor();
}
