var Flickity = require('flickity');
// require('flickity-hash');

function initProjectSlider() {
	var slider = new Flickity('.project-wrapper .slider', {
		cellAlign: 'center',
		cellSelector: '.slide',
		wrapAround: true,
		selectedAttraction: 1,
		friction: 1,
		pageDots: false,
		draggable: false,
		// hash: true,
		lazyLoad: 2,
		// lazyLoad: true,
	});

	/**
	 *  Pagination Index
	 *
	 */

	var sliderIndex = document.querySelector('.slider-pagination');

	function updateStatus() {
		sliderIndex.innerText = `${slider.selectedIndex + 1}/${slider.slides.length}`;
	}

	updateStatus();

	slider.on('change', updateStatus);
}

function initProjectSliderCursor() {
	let prevBtn = document.querySelector('.flickity-prev-next-button.previous');
	let nextBtn = document.querySelector('.flickity-prev-next-button.next');
	let prevCursor = document.querySelector('.slider .cursor--prev');
	let nextCursor = document.querySelector('.slider .cursor--next');

	function cursor(btn, cursor) {
		if (btn && cursor) {
			function hideCursor(cursor) {
				if (cursor.classList.contains('is-visible')) {
					cursor.classList.remove('is-visible');
				}
			}

			function showCursor(cursor) {
				if (!cursor.classList.contains('is-visible')) {
					cursor.classList.add('is-visible');
				}
			}

			function placeCursor(event, cursor) {
				var x = event.clientX;
				var y = event.clientY;
				cursor.style.left = x - 10 + 'px';
				cursor.style.top = y - 10 + 'px';
			}

			btn.addEventListener('mouseleave', () => hideCursor(cursor));
			btn.addEventListener('mousemove', () => showCursor(cursor));
			btn.addEventListener('mousemove', (event) => placeCursor(event, cursor));
		}
	}

	cursor(prevBtn, prevCursor);
	cursor(nextBtn, nextCursor);
}

export { initProjectSlider, initProjectSliderCursor };
